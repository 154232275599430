import React from 'react'
import './Join.css'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'


const Join = () => {
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_i2ustnw', 'template_kyvbscc', form.current, 'nQ7fhgo_fUeJHv1Yp')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
  return (
    <div className='Join' id='join-us'>
        <div className='left-j'>
            <hr/>
            <div>
                <span className='stroke-text'> READY TO </span>
                <span> LEVEL UP </span>
            </div>
            <div>
                <span> YOUR BODY </span>
                <span className='stroke-text'> WITH US ?</span>
            </div>
        </div>
        <div className='right-j'>
            <form ref={form} className="email-container" onSubmit={sendEmail}>
                <input type='email' name='user-email' placeholder='Enter your Email here'/>
                <button className='btn btn-j'>Join Now</button>
          
            </form>
            {/* <span className='stroke-text'> READY TO </span>
            <span> JOIN US ?</span>
            <span>
                Let's get started
            </span> */}
            
        </div>
      
    </div>
  )
}

export default Join
