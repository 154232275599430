import React from 'react'
import './Header.css'
import Logo from '../assets/logo.png';
import BArs from '../assets/bars.png';
import { useState } from 'react'
import {Link} from 'react-scroll'

function Header() {
  const mobile = window.innerWidth <= 768 ? true : false
  const [open, setOpen] = useState(false)
  return (
    <div className='Header'>
        <img src={Logo} alt='Logo' className='logo'/>
      {(open===false && mobile===true)?(
        <div
        style={{backgroundColor: 'var(--appColor)',padding:'0.5rem',borderRadius:'5px'}}
        onClick={()=>setOpen(true)}
        ><img src={BArs} alt=''
        style={{width:'1.5rem',height:'1.5rem',color:'white'}}
        /></div>
      ):
      <ul className='header-menu'>
      <li ><Link
      onClick={()=>setOpen(false)}
      to='Home'
      span={true}
      smooth={true}
      >Home</Link></li>
      
      <li ><Link
      onClick={()=>setOpen(false)}
      to='Programs'
      span={true}
      smooth={true}
      >Programs</Link></li>
      
      <li ><Link
      onClick={()=>setOpen(false)}
      to='Why us'
      span={true}
      smooth={true}
      >Why us</Link></li>

     <li ><Link
      onClick={()=>setOpen(false)}
      to='Plans'
      span={true}
      smooth={true}
      >Plans</Link></li>

      <li ><Link
      onClick={()=>setOpen(false)}
      to='Testimonials'
      span={true}
      smooth={true}
      >Testimonials</Link></li>
  </ul>}
        
      
    </div>
  )
}

export default Header
