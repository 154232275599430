import React from 'react'
import './Hero.css';
import Header from './Header';
import Heart from '../assets/heart.png'
import hero_image from '../assets/hero_image.png'
import hero_image_back from '../assets/hero_image_back.png'
import calories from '../assets/calories.png'
import {motion} from 'framer-motion' //framermotion from
import NumberCounter from 'number-counter'

const Hero = () => {
    const transition = {type: 'spring', duration: 3}
    const mobile = window.innerWidth <= 768 ? true : false
  return (
    <div className='Hero' id='Home'>

        <div className="blur hero-blur"></div>
        <div className="left-h">
            <Header/>
            {/*The best ad*/}
            <div className="best-ad">
                <motion.div
                 initial={{left:mobile? '160px': "238px"}} whileInView={{left: '8px'}} transition={{...transition,
                 type: 'tween' 
                 }}></motion.div>
                <span>Emmanuel Adomako Frimpong </span>
            </div>

            {/*Hero Heading*/}
            <div className="hero-text">
                <div>
                    <span className='stroke-text'>Shape </span>
                    <span>Your</span>
                </div>
                <div>
                    <span>Ideal Body</span>
                </div>
                <div>
                    <span>In here we will help you to shape and build your ideal body
                    and live up your life to fullest</span>
                </div>
            </div>
            {/*figure*/}
            <div className="figures">
                <div>
                    <span>
                        <NumberCounter end={140} start={100} delay='4' preFix="+"/>
                    </span>
                    <span>expert coachs</span>
                </div>
                <div>
                    <span><NumberCounter end={978} start={500} delay='4' preFix="+"/></span>
                    <span>members joined</span>
                </div>
                <div>
                    <span><NumberCounter end={70} start={1} delay='4' preFix="+"/></span>
                    <span>fitness programs</span>
                </div>
            </div>
            {/*hero buttons*/}
            <div className="hero-buttons">
                <button className="btn">Get Started</button>
                <button className="btn">Learn More</button>
            </div>
        </div>
        <div className="right-h">
            <button className="btn">Join Now</button>
            <motion.div 
            transition={transition}
            whileInView={{right:"4rem"}}
            initial={{right: '-1rem'}}
            className="heart-rate">
                <img src={Heart} alt='heart' />
                <span>Heart Rate</span>
                <span>116 bpm</span>
            </motion.div>
            <img src={hero_image} alt='hero' className='hero-img' />
            <motion.img
            transition={transition}
            whileInView={{right:"20rem"}}
            initial={{right: '11rem'}}
            src={hero_image_back} alt='hero' className='hero-img-back' />
            {/*calories*/}
            <motion.div
            transition={transition}
            whileInView={{right:"41rem"}}
            initial={{right: '35rem'}}
            className="calories">
                <img src={calories} alt='calories' />
                <div>
                    <span>Calories Burned</span>
                    <span>220 kcal</span>
                </div>
            </motion.div>



        </div>
      
    </div>
  )
}

export default Hero
